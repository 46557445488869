import { FC, useState } from 'react';

import { Card } from '@interstate/components/Card';
import { SelectInput } from '@interstate/components/SelectInput';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

import { Dealership } from '../../types';
import { SelectInputContainer } from './DealershipSelect.style';
import { OpenDealCardHeader } from '../openDealCardHeader/OpenDealCardHeader';
import { OpenDealCardInfo } from '../Activity.style';

export type DealershipSelectProps = {
    dealers: Array<Dealership>;
    dealerId?: number;
    onDealershipSelect: (dealerId: number) => void;
};

export const DealershipSelect: FC<DealershipSelectProps> = ({ dealers, dealerId, onDealershipSelect }) => {
    const theme = useInterstateTheme();
    const [editMode, setEditMode] = useState(() => false);
    const isAlreadyStarted = dealerId !== undefined;
    const selectedDealer = dealerId ? dealers.find((dealer) => dealer.dealerId === dealerId) : undefined;

    return (
        <Card
            sx={{ backgroundColor: theme.tokens.BaseColorGray50 }}
            data-testid="dealership-select"
            header={
                <OpenDealCardHeader
                    title="Dealership (required)"
                    showIcon
                    isComplete={isAlreadyStarted}
                    action={editMode ? 'Close' : isAlreadyStarted ? 'Edit' : 'Start'}
                    onActionClick={() => setEditMode(!editMode)}
                />
            }
            content={
                editMode ? (
                    <SelectInputContainer hasValue={!!dealerId}>
                        <SelectInput
                            data-testid="dealership-select-input"
                            onChange={(event) => {
                                onDealershipSelect(Number(event.target.value));
                                setEditMode(!editMode);
                            }}
                            name={'DealershipSelectInput'}
                            options={dealers.map((dealer) => ({ value: `${dealer.dealerId}`, label: dealer.name }))}
                            value={`${dealerId}`}
                            required={false}
                            displayDeselectOption={false}
                            placeholder="Select"
                        ></SelectInput>
                    </SelectInputContainer>
                ) : selectedDealer ? (
                    <OpenDealCardInfo data-testid="dealership-select-dealer-name">{selectedDealer.name}</OpenDealCardInfo>
                ) : null
            }
        ></Card>
    );
};
