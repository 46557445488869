// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';
import { ToggleUseSource, toggleUseRegistry } from '@makemydeal/dr-feature-toggles-client';

// interfaces/types
import { FeatureTogglesAndUrlOnly } from '../types';

// utils
import { clientLogger } from '@makemydeal/dr-shared-ui-utils';
import {
    getFeatureToggleFromSlice,
    isIncentivesActivityEnabledFromSlice,
    isMenuActivityEnabledFromSlice,
    isTradeActivityEnabledFromSlice
} from '../reducers/featureToggleReducer';

// selectors
import { wasBootstrapSuccessful } from './sharedBootstrapSelectors';
import { FeatureToggles } from '@makemydeal/dr-platform-shared';

export type FeatureTogglePreconditionCheckOptions = {
    throwError?: boolean;
    logMessage?: boolean;
};

const PRECONDITION_CHECK_DEFAULTS = { throwError: true };

/**
 * Every feature toggle selector should include this check.  By default this will throw an error if toggled code is used before
 * the app has been bootstrapped.  If there's a scenario where it is "safe" to avoid this you can override the behavior by using
 * the options argument.
 *
 * NOTE: "safe" scenarios are defined as code that can be executed regardless of the toggle state- this is rare because the most
 * common use for feature toggles is to prevent untoggled code from executing at all times.  One possible "safe" use-case is if the
 * UI checks the state initially and hides elements and then later shows elements but this doesn't cause undesirable flicker.
 *
 * IMPORTANT: Any "unsafe" feature toggle selectors (those that do not throw exceptions if they are used before bootstrap completes
 *   should have a name like `isXyzEnabledAfterBootstrap` instead of just `isXyzEnabled`).
 */
export const featureTogglePreconditionCheck = (
    state: StateTree,
    options: FeatureTogglePreconditionCheckOptions = PRECONDITION_CHECK_DEFAULTS
) => {
    if (!areFeatureTogglesAvailable(state)) {
        if (options.logMessage) {
            clientLogger.clientWarnMessage('Feature toggles retrieved before app has successfully bootstrapped.');
        }
        if (options.throwError) {
            throw new Error('Feature toggles retrieved before app has successfully bootstrapped.');
        }
    }
};

export const areFeatureTogglesAvailable = (state: StateTree) => {
    return wasBootstrapSuccessful(state);
};

/**
 * Returns feature toggles with the ability to set pre-condition checks to various levels defined by the options parameter.  If
 * omitted the default behavior will protect the calling code from using feature toggles prior to bootstrap completion.
 */
export const getFeatureToggles = (
    state: StateTree,
    options: FeatureTogglePreconditionCheckOptions = PRECONDITION_CHECK_DEFAULTS
): Partial<FeatureTogglesAndUrlOnly> => {
    featureTogglePreconditionCheck(state, options);
    return state.featureToggles || ({} as Partial<FeatureTogglesAndUrlOnly>);
};

/**
 * Similar to getFeatureToggles but converts feature toggles into a more loosely typed object keyed by the toggle name.  This is
 * useful when passing the feature toggles to some activities.
 *
 * NOTE: In future we will not support this- the feature toggles should not be passed directly into the activity, they should
 *   instead use feature toggle selectors and pass the toggles in via the activity's additionalSelectors.
 */
export const getSimpleFeatureToggles = (
    state: StateTree,
    options: FeatureTogglePreconditionCheckOptions = PRECONDITION_CHECK_DEFAULTS
): FeatureToggles => {
    const featureToggles = getFeatureToggles(state, options);
    return featureToggles as FeatureToggles;
};

/**
 * Returns feature toggle data without error before bootstrap has completed.  Using this incorrectly could result in UI flicker,
 * skipped business logic or race conditions.  Only use this if you're sure that the logic isn't prone to race conditions and will
 * handle the initial "false" toggle value that could change to "true" after bootstrap completion.
 */
export const getTogglesWithoutSafetyChecks = (state: StateTree): Partial<FeatureTogglesAndUrlOnly> => getFeatureToggles(state, {});

/**
 * To make it easier to port code from shopper platform this function is aliased.  See how `getFeatureToggles` is defined above
 * for more information.
 */
export const getFeatureToggleState = (state: StateTree): Partial<FeatureTogglesAndUrlOnly> => getFeatureToggles(state);

/**
 * Get a feature toggle by name in a type-safe way - this will ensure that the caller uses a feature toggle name that exists in the
 * FeatureTogglesAndUrlOnly type.
 */
export const getFeatureToggle = (
    state: StateTree,
    name: keyof FeatureTogglesAndUrlOnly,
    source: ToggleUseSource = ToggleUseSource.Selector
): boolean => {
    toggleUseRegistry.registerToggleUse(name, source);
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return getFeatureToggleFromSlice(featureToggles, name);
};

/**
 * NOTE: This should only be used by the useFeatureToggle hook.
 */
export const featureEnabledSelectorForHook = (name: keyof FeatureTogglesAndUrlOnly) => (state: StateTree) =>
    getFeatureToggle(state, name, ToggleUseSource.Hook);

export const isCreditBureauEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'isCreditBureauEnabled');

export const isShowUniFiDeepLinkEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'showUniFiDeepLink');

export const isAccessoriesEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'accessories');

export const isAccessoriesCatalogEnabled = (state: StateTree): boolean =>
    getFeatureToggle(state, 'dashUseAccessoriesCatalogActivity');

export const showRecommendedOnVppRates = (state: StateTree): boolean => getFeatureToggle(state, 'showRecommendedOnVppRates');

export const isChangeVehicleEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enableChangeVehicle');

export const isPdfPrintMvEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enablePdfPrintMv');

export const enableMenuOnCash = (state: StateTree): boolean => getFeatureToggle(state, 'enableMenuOnCash');

export const enableMenuSelectionsRevertLogic = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableMenuSelectionsRevertLogic');

export const isCashEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'showCashOffer');

export const isMenuPaymentsDisclaimerEnabled = (state: StateTree): boolean =>
    getFeatureToggle(state, 'isMenuPaymentsDisclaimerEnabled');

export const isMenuUpdateWhenSdpTermsChangeEnabled = (state: StateTree): boolean =>
    getFeatureToggle(state, 'menuUpdateWhenSdpTermsChange');

export const isNewPaymentWorkFlow = (state: StateTree): boolean => getFeatureToggle(state, 'enableNewPaymentWorkFlowChange');

export const isVppMonthlyPaymentEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'isVppMonthlyPaymentEnabled');

export const showMenuPaymentError = (state: StateTree): boolean => getFeatureToggle(state, 'showMenuPaymentError');

export const viewDetailsVppNonRatedLongText = (state: StateTree): boolean =>
    getFeatureToggle(state, 'viewDetailsVppNonRatedLongText');

export const isLambdaVppEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'useLambdaVppDrDash');

export const isPaymentBuildingCardsEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enablePaymentBuildingCards');

export const isCreditOverwriteBlockEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'creditOverwriteBlock');

export const isSnapshotEnabledForCMD = (state: StateTree): boolean => getFeatureToggle(state, 'enableSnapshotForCMD');

export const isEnableLenderDecisionsInCreditBureau = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableLenderDecisionsInCreditBureau');

export const isEnableItemizedIncentivesSDP = (state: StateTree): boolean => getFeatureToggle(state, 'enableItemizedIncentivesSDP');

export const testDriveAfterTradeIn = (state: StateTree): boolean => getFeatureToggle(state, 'testDriveAfterTradeIn');

export const isPencilServiceEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'isPencilServiceEnabled');

export const usePaymentOnlyMenuSelections = (state: StateTree): boolean => getFeatureToggle(state, 'dashPaymentOnlyMenuSelections');

export const isToggleFsDealRefIdEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'toggleFsDealRefId');

export const isVAutoEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'isVAutoEnabled');

export const isEnableDealerCommentsEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enableDealerComments');

export const isEnableBuyRateForCreditDecision = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableBuyRateForCreditDecision');

export const isDpmEnabledAfterBootstrap = (state: StateTree): boolean => getTogglesWithoutSafetyChecks(state).enableDpm || false;

export const isDealLifeCycleEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enableDealLifeCycle');

export const isInterstateUiEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enableInterstateUi');

export const isLenderLogosEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enableLenderLogos');

// #region Activity Enabled Toggles
export const isIncentivesActivityEnabledAfterBootstrap = (state: StateTree): boolean =>
    isIncentivesActivityEnabledFromSlice(getTogglesWithoutSafetyChecks(state));

export const isMenuActivityEnabledAfterBootstrap = (state: StateTree): boolean =>
    isMenuActivityEnabledFromSlice(getTogglesWithoutSafetyChecks(state));

export const isTradeActivityEnabledAfterBootstrap = (state: StateTree): boolean =>
    isTradeActivityEnabledFromSlice(getTogglesWithoutSafetyChecks(state));

export const getEnableAppliedLenderInfo = (state: StateTree): boolean => getFeatureToggle(state, 'enableAppliedLenderInfo');

export const isEnableCustomerCardNotifications = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableCustomerCardNotifications');

// #endregion

export const isGtmDataLayerV2Enabled = (state: StateTree): boolean => getFeatureToggle(state, 'isGtmDataLayerV2Enabled');

export const isDCAccessoriesEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enableDCaccessories');

export const isSmartInitOfferSaveForDashEnabled = (state: StateTree): boolean =>
    getFeatureToggle(state, 'smartInitOfferSaveForDash');

export const isUsePSTotalAmountDueForCashDealsEnabled = (state: StateTree): boolean =>
    getFeatureToggle(state, 'usePSTotalAmountDueForCashDeals');

export const isWalkMeMvEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enableWalkMeMv');

export const isCalculationEndpointEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'useCalculationEndpoint');

export const isPushToDmsSmartSaveEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'pushToDmsSmartSave');

export const usePaymentProductsMonthlyPayment = (state: StateTree): boolean =>
    getFeatureToggle(state, 'usePaymentProductsMonthlyPayment');

export const useCoOrgIdInVAutoAppraisalUrl = (state: StateTree): boolean =>
    getFeatureToggle(state, 'useCoOrgIdInVAutoAppraisalUrl');

export const useAccessoriesFromInitCall = (state: StateTree): boolean => getFeatureToggle(state, 'useAccessoriesFromInitCall');

export const isPlanSelectionForVppEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enablePlanSelectionForVPP');

export const useEnhancedTradeInMV = (state: StateTree): boolean => getFeatureToggle(state, 'useEnhancedTradeInMV');

export const isOverrideTaxRateForPaymentEnabled = (state: StateTree): boolean =>
    getFeatureToggle(state, 'useOverrideTaxRateForPayment');

export const isOverrideTaxAmountForPaymentEnabled = (state: StateTree): boolean =>
    getFeatureToggle(state, 'useOverrideTaxAmountForPayment');

export const enableHideRouteOneCreditBureau = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableHideRouteOneCreditBureau');

export const enableEnhancedPushToDmsPlusMV = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableEnhancedPushToDmsPlusMV');

export const isSellAnyVehicleEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enableSellAnyVehicle');
export const enableCreditBureauAndDecisionPBCs = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableCreditBureauAndDecisionPBCs');

export const enableCreditBureauAndDecisionPBCRouteOne = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableCreditBureauAndDecisionPBCRouteOne');

export const useAnyTermForPayment = (state: StateTree): boolean => getFeatureToggle(state, 'useAnyTermForPayment');

export const enablePendoInMV = (state: StateTree): boolean => getFeatureToggle(state, 'enablePendoInMV');

export const enableCommonOrgIdForPendo = (state: StateTree): boolean => getFeatureToggle(state, 'enableCommonOrgIdForPendo');

export const convertDecimalTaxRateToPercentInMV = (state: StateTree): boolean =>
    getFeatureToggle(state, 'convertDecimalTaxRateToPercentInMV');

export const enablePushToR1 = (state: StateTree): boolean => getFeatureToggle(state, 'enablePushToR1');
export const enableLenderFeeEdits = (state: StateTree): boolean => getFeatureToggle(state, 'enableLenderFeeEdits');

export const enableOptionalPbcUi = (state: StateTree): boolean => getFeatureToggle(state, 'enableOptionalPbcUi');

export const usePaymentGridMV = (state: StateTree): boolean => getFeatureToggle(state, 'usePaymentGridMV');

export const useOverrideTaxAmountForPayment = (state: StateTree): boolean =>
    getFeatureToggle(state, 'useOverrideTaxAmountForPayment');

export const isABPencilStateMVEnabled = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableABPencilStateMV') && state.__isScoped === true;

export const enableMultiscenarioPrintMV = (state: StateTree): boolean => getFeatureToggle(state, 'enableMultiscenarioPrintMV');

export const enableRelaxSoldState = (state: StateTree): boolean => getFeatureToggle(state, 'enableRelaxSoldState');
export const enableConsumerInsightsLambdaV2 = (state: StateTree): boolean =>
    getFeatureToggle(state, 'enableConsumerInsightsLambdaV2');

export const enableEcommerceDealWithILDP = (state: StateTree): boolean => getFeatureToggle(state, 'enableEcommerceDealWithILDP');

export const isPushToDarwinEnabled = (state: StateTree): boolean => getFeatureToggle(state, 'enablePushToDarwin');

export const enableMetadataForPendo = (state: StateTree): boolean => getFeatureToggle(state, 'enableMetadataForPendo');

export const getEnhancedDealDetails = (state: StateTree): boolean => getFeatureToggle(state, 'enhancedDealDetails_F209893');
