// istanbul ignore file
// TODO: DASH - this has been copied from Shopper Platform - it may be better to have this in a shared library?

// libraries
import type { IDealSummaryItem as DealSummaryItem, IPaymentTerm as PaymentTerm } from '@makemydeal/dr-platform-shared';
import type { ITradeInState as TradeInState } from '@makemydeal/dr-activities-common';
import type { InitPayloadOffer, Lead, SaveOfferPayload } from '@makemydeal/dr-dash-bff-types';
import type { StateTree } from '@makemydeal/dr-dash-types';
import type { Dealer } from '@makemydeal/dr-shared-types';
import { vehicleUtils, formatUtils } from '@makemydeal/dr-common-utils';
import { CASH, FINANCE, IVehicle, LEASE, IOffer as Offer, paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { dealerSelectors, featureToggleSelectors, selectedOfferSelectors } from '@makemydeal/dr-shared-store';
import { queryParamStore } from '@makemydeal/dr-shared-ui-utils';
import { utils as offerReduxUtils } from '@makemydeal/dr-offer-redux';

// selectors
import * as compositeSelectors from '../selectors/compositeSelectors';
import * as connectionSelectors from '../selectors/connectionSelectors';
import * as createDealSelectors from '../selectors/createDealSelectors';
import * as creditAppSelectors from '../selectors/creditAppSelectors';
import * as leadFormInfoSelectors from '../selectors/leadFormInfoSelectors';
import * as leadSelectors from '../selectors/leadSelectors';
import * as metaDataSelectors from '../selectors/metaDataSelectors';
import * as offerInfoSelectors from '../selectors/offerInfoSelectors';
import * as offerReduxSelectors from '../selectors/offerRedux';
import * as offerSelectors from '../selectors/offerSelectors';
import * as pushToDmsSelectors from '../selectors/pushToDmsSelectors';
import * as offerAdditionalSelectors from '../selectors/offerAdditionalSelectors';
import * as tradeInSelectors from '../selectors/trade';
import * as vdpSourceSelectors from '../selectors/vdpSourceSelectors';
import * as widgetSettingsSelectors from '../selectors/widgetSettingsSelectors';
import * as dealXgDetailsSelectors from '../selectors/dealXgDetailsSelectors';

// interfaces/types
import { BuildPayloadPurpose } from '../types/offerPayloadUtilsEnums';

// TODO: DASH - move this to the credit reducer when it has been added

const isLeadEmpty = (lead: Lead | null) => {
    // no reason to import lodash when native can do the same thing
    return (lead && Object.keys(lead).length === 0 && Object.getPrototypeOf(lead) === Object.prototype) || !lead?.id;
};

// TODO: DASH - define payload type below

const buildOfferTermPayload = (payload: any, state: StateTree) => {
    const financeInputs = offerReduxSelectors.getOfferDetails(state, FINANCE) || {};
    const leaseInputs = offerReduxSelectors.getOfferDetails(state, LEASE) || {};
    const cashInputs = offerReduxSelectors.getOfferDetails(state, CASH) || {};
    const penciledInputs = {
        finance: {
            downPayment: financeInputs.downPayment,
            offerPrice: financeInputs.offerPrice,
            overridesByTerm: financeInputs.overridesByTerm,
            retailPriceOverride: financeInputs.retailPriceOverride,
            term: financeInputs.term,
            creditDecisionEnabled: financeInputs.creditDecisionEnabled,
            // TODO Delete creditDecisionLenderName after feature toggle enableAppliedLenderInfo has been deleted
            creditDecisionLenderName: financeInputs.creditDecisionLenderName,
            creditDecisionLender: financeInputs.creditDecisionLender,
            creditDecisionStatus: financeInputs.creditDecisionStatus,
            incentiveTotalOverride: financeInputs.incentiveTotalOverride,
            manualIncentives: financeInputs.manualIncentives,
            manualTotalTax: financeInputs.manualTotalTax
        },
        lease: {
            acqFeeOverride: leaseInputs.acqFeeOverride,
            acqFeeUpFrontOverride: leaseInputs.acqFeeUpFrontOverride,
            annualMiles: leaseInputs.annualMiles,
            downPayment: leaseInputs.downPayment,
            offerPrice: leaseInputs.offerPrice,
            overridesByTerm: leaseInputs.overridesByTerm,
            retailPriceOverride: leaseInputs.retailPriceOverride,
            securityDepositOverride: leaseInputs.securityDepositOverride,
            term: leaseInputs.term,
            creditDecisionEnabled: leaseInputs.creditDecisionEnabled,
            // TODO Delete creditDecisionLenderName after feature toggle enableAppliedLenderInfo has been deleted
            creditDecisionLenderName: leaseInputs.creditDecisionLenderName,
            creditDecisionLender: leaseInputs.creditDecisionLender,
            creditDecisionStatus: leaseInputs.creditDecisionStatus,
            incentiveTotalOverride: leaseInputs.incentiveTotalOverride,
            mileageChargeOverride: leaseInputs.mileageChargeOverride,
            manualIncentives: leaseInputs.manualIncentives,
            manualTotalTax: leaseInputs.manualTotalTax
        },
        cash: {
            appliedIncentiveAmount: offerReduxSelectors.getAllIncentiveTotalByOfferType(state, CASH),
            appliedIncentiveIds: cashInputs.appliedIncentiveIds,
            appliedIncentives: cashInputs.appliedIncentives,
            dealerFees: cashInputs.dealerFees,
            dealerFeesTotal: cashInputs.dealerFeesTotal,
            dmvFees: cashInputs.dmvFees,
            incentives: cashInputs.incentives,
            initialOfferPrice: cashInputs.initialOfferPrice,
            offerPrice: cashInputs.offerPrice,
            retailPriceOverride: cashInputs.retailPriceOverride,
            selectedIncentives: cashInputs.selectedIncentives,
            taxBreakdown: cashInputs.taxBreakdown,
            totalTax: cashInputs.totalTax,
            manualIncentives: cashInputs.manualIncentives,
            manualTotalTax: cashInputs.manualTotalTax
        }
    };
    payload.terms = {
        paymentData: offerReduxSelectors.getTermsByIdForOffer(state.offer),
        penciledInputs
    };
    payload.selectedFinanceTerm = offerReduxSelectors.getTermIdForOfferType(state.offer, FINANCE);
    payload.selectedLeaseTerm = offerReduxSelectors.getTermIdForOfferType(state.offer, LEASE);
    return payload;
};

export const getOverrideFeesIds = (feesOverrides: paymentServicesTypes.FeeOverride[]) =>
    feesOverrides.map((fee) => fee.feeTypeId).flat();

/**
 * Create the payload necessary to hit the Offer Service
 * @param state - Current Application State
 */
export const createGatewayOfferPayload = (state: StateTree, offerUpdateType?: string, purpose = BuildPayloadPurpose.Unknown) => {
    const applicationName = 'CMD';

    const offerType = offerReduxSelectors.getCurrentOfferType(state);
    const isCash = offerType === CASH;

    const isEnabledFniFailure: boolean | undefined = undefined; // enableFniFailure();
    const isEnabledFniFailFeatureToggle: boolean | undefined = undefined; // enableFniFailFeatureToggle();
    const commonOrgId = offerReduxSelectors.getCommonOrgId(state);
    const commonConsumerId = offerReduxSelectors.getShopperCommonConsumerId(state);
    const currentCalculationEndpoint = offerReduxSelectors.getCurrentCalculationEndpoint(state);
    const isUserProgramQuotes = offerReduxSelectors.getIsUserProgramQuotes(state);
    const dealerId = dealerSelectors.getDealerId(state);
    const offerSummary = offerReduxSelectors.getOfferTerm(state);
    const { downPayment, offerPrice, initialOfferPrice } = offerReduxSelectors.getOfferDetails(state, offerType);
    const dealSummary = (offerSummary as any).dealSummary || [];
    const creditAppReferenceId = creditAppSelectors.getCreditAppReferenceId(state);
    const accelerateCreditAppId = creditAppSelectors.getAccelerateCreditAppId(state);
    const coApplicantEmail = offerReduxSelectors.getCreditAppCoApplicantEmail(state);
    const selectedShopper = offerReduxSelectors.getShopperInfo(state);
    const email = selectedShopper.email ? selectedShopper.email.trim() : '';
    const shopper = {
        ...selectedShopper,
        email,
        firstName: selectedShopper.firstName ? selectedShopper.firstName.trim() : '',
        lastName: selectedShopper.lastName ? selectedShopper.lastName.trim() : '',
        zip: selectedShopper.zip || dealerSelectors.getDealerZip(state)
    };
    const coBuyer = offerReduxSelectors.getCoBuyerInfo(state);
    const route = widgetSettingsSelectors.getSourceRoute(state);
    const vdpUrl = vdpSourceSelectors.getVdpUrl(state);
    const sponsor = vdpSourceSelectors.getVdpSponsor(state);
    const tradeIn = tradeInSelectors.getTradeIn(state);
    const tradeInStatus = tradeInSelectors.getTradeInStatus(state);
    const connectionId = connectionSelectors.getConnectionId(state);
    const hasBeenSent = offerInfoSelectors.getHasBeenSent(state);
    const selectedVehicle = offerReduxSelectors.getVehicleDetails(state);
    const deviceType = offerReduxSelectors.getDeviceType(state);
    const offerId = offerInfoSelectors.getOfferId(state);
    const sessionId = offerReduxSelectors.getSessionId(state);
    const vehicle = {
        ...selectedVehicle,
        condition: selectedVehicle?.condition?.toLowerCase()
    } as IVehicle;
    const canNegotiateNew = !dealerSelectors.getIsNewVehicleSinglePrice(state);
    const canNegotiateUsed = !dealerSelectors.getIsUsedVehicleSinglePrice(state);
    const isSinglePrice = vehicleUtils.isSinglePrice(canNegotiateNew, canNegotiateUsed, vehicle.condition);
    const listingCode = vdpSourceSelectors.getListingCode(state, sponsor);
    const metaData = metaDataSelectors.getMetaData(state);
    const totalIncentives =
        offerType === CASH
            ? offerReduxSelectors.findAllIncentiveTotalFromOffer(state.offer, offerType)
            : offerSummary.appliedIncentiveAmount;
    const messageToDealer = offerReduxSelectors.getMessage(state);
    const dealRefId = offerReduxSelectors.getDealRefId(state) || offerSelectors.getDealRefId(state); // TODO: clean up dealRefId
    const fsDealRefId = offerReduxSelectors.getFsDealRefId(state) || offerSelectors.getDealRefId(state);
    const deal_ref_id = offerReduxSelectors.getDealRefIdFS(state); // Temp value, during finance services migration
    const hasShopperEngagedWithOffer = createDealSelectors.hasShopperEngagedWithOffer(state);
    const dealExchangeDealId = selectedOfferSelectors.getSelectedOfferDealXgId(state);
    const dealExchangeVersionId = selectedOfferSelectors.getSelectedOfferDealXgVersion(state);

    dashDebugAlert(purpose, dealExchangeVersionId);

    const offerSource = offerReduxSelectors.getOfferSource(state) || compositeSelectors.getPersona(state);
    const shortDisclaimerData = offerReduxSelectors.getShortDisclaimerData(state);
    const paymentDisclaimer = formatUtils.formatShortDisclaimer(shortDisclaimerData);
    const cmdMessageToShopper = offerReduxSelectors.getCmdMessageToShopper(state);
    const cmdMessageToSalesperson = offerReduxSelectors.getCmdMessageToSalesperson(state);
    const creditAppId = offerReduxSelectors.getCreditAppId(state);
    const globalCustomerId = offerReduxSelectors.getGlobalCustomerId(state);
    const isIncentivesCompleted = Boolean(offerReduxSelectors.getSelectedConditionalIncentivesToDisplay(state)?.length);
    const isTermsCompleted = true; // Terms activity is marked completed when offer is submitted

    // https://ghe.coxautoinc.com/DigitalRetailing/dr-dash/issues/801
    let payload: SaveOfferPayload = {
        offerId,
        ...(coBuyer != null && { coBuyer }), // include coBuyer if it has a value
        commonConsumerId,
        commonOrgId,
        currentCalculationEndpoint,
        dealerId,
        dealExchangeDealId,
        dealExchangeVersionId,
        enableAppliedLenderInfo: featureToggleSelectors.getEnableAppliedLenderInfo(state),
        isEnabledFniFailure,
        isEnabledFniFailFeatureToggle,
        isUserProgramQuotes,
        dealRefId, // TODO: clean up dealRefId
        deal_ref_id,
        fsDealRefId,
        type: offerType,
        offerPrice,
        initialOfferPrice,
        totalFee: offerSummary.totalFee,
        totalTax: offerSummary.totalTax,
        downPayment: isCash ? offerAdditionalSelectors.getMvCashOfferRoundedUp(state) : downPayment,
        totalFinanced: isCash ? undefined : (offerSummary as PaymentTerm).amountFinanced,
        monthlyPayment: isCash ? undefined : (offerSummary as PaymentTerm).monthlyPayment,
        monthlyPaymentRounded: isCash ? undefined : (offerSummary as PaymentTerm).monthlyPaymentRounded,
        dealSummary,
        creditApp: {
            creditAppReferenceId,
            accelerateCreditAppId,
            coApplicantEmail
        },
        creditAppId,
        creditScore: offerReduxUtils.buildCreditScore(state),
        incentives: {
            ...state.offer?.incentives,
            total: totalIncentives || 0
        },
        shopper,
        source: {
            listingCode,
            route,
            vdpUrl,
            sponsor
        },
        vehicle,
        isSinglePrice,
        tradeIn: tradeInStatus.isCompleted ? tradeIn : {},
        isTradeCompleted: tradeInStatus.isCompleted,
        isIncentivesCompleted,
        isTermsCompleted,
        isTradeSkipped: tradeInStatus.isSkipped,
        bypassDealSummary: true,
        connectionId,
        payment: {
            ...offerSummary,
            ...(featureToggleSelectors.isOverrideTaxRateForPaymentEnabled(state) && {
                taxItems: offerReduxSelectors.getTaxItemsWithOverrides(state, offerType)
            })
        },
        messageToDealer,
        offerUpdateType: offerUpdateType || undefined,
        hasBeenSent,
        deviceType,
        metaData,
        sessionId,
        isTestLead: leadFormInfoSelectors.isTestLead(state),
        hasShopperEngagedWithOffer,
        offerSource,
        paymentDisclaimer,
        paymentTermsFallback: offerSelectors.getPaymentTermsFallback(state),
        cmdMessageToShopper,
        cmdMessageToSalesperson,
        globalCustomerId,
        transferToDMS: pushToDmsSelectors.getTransferToDMS(state),
        applicationName,
        latestPushToDmsPlus: dealXgDetailsSelectors.getLatestPushToDmsPlus(state)
    };

    if (featureToggleSelectors.isPencilServiceEnabled(state)) {
        payload = buildOfferTermPayload(payload, state);
    }
    const lead = leadSelectors.getLead(state);
    if (!isLeadEmpty(lead)) {
        payload = { ...payload, ...{ lead } };
    }

    if (offerType === FINANCE) {
        const { months, apr } = offerSummary as PaymentTerm;
        payload = {
            ...payload,
            ...{
                financeTerms: { term: months, apr } as any // TODO: Let John Richardson know to fix this on 2024-02-01
            }
        };
    } else if (offerType === LEASE) {
        const { months } = offerSummary as PaymentTerm;
        const leaseAnnualMiles = offerReduxSelectors.getAnnualMiles(state);
        const mileage = leaseAnnualMiles && leaseAnnualMiles > 0 ? leaseAnnualMiles : 1;
        payload = {
            ...payload,
            ...{
                leaseTerms: { term: months, mileage }
            },
            deductDueAtSigning: dealerSelectors.getIsDueAtSigninAmountDeduct(state),
            capCostDetails: {
                adjustedCapCost: offerReduxSelectors.getAdjustedCapCost(state),
                capReductionCash: offerReduxSelectors.getCapCostReduction(state, offerType),
                grossCapCost: offerReduxSelectors.getCapCostTotal(state)
            }
        } as any; // TODO: Let John Richardson know to fix this on 2024-02-01
    } else {
        // offerType is cash
        const { dealerFees, dmvFees } = offerReduxSelectors.getOfferDetails(state, offerType);
        payload.fees = dealerFees || [];
        payload.dmvFees = dmvFees || 0;
    }

    return payload;
};

export const createLambdaOfferPayload = (state: StateTree) => {
    // // TODO: DASH - add this if needed
    const lambdaOfferPayload = {
        dealer: state.dealer,
        offer: state.offer,
        selectedOffer: state.selectedOffer,
        offerInfo: state.offerInfo,
        payment: state.payment,
        featureToggles: state.featureToggles,
        creditDecision: state.creditDecision,
        trade: tradeInSelectors.getTradeIn(state),
        menu: state.menu,
        metaData: state.metaData,
        vdpSource: state.vdpSource,
        widgetSettings: state.widgetSettings
    };

    return lambdaOfferPayload;
};

const formatListingOverride = (state: StateTree) => {
    const listingOverride = dealerSelectors.getListingOverride(state);
    if (!listingOverride) {
        return undefined;
    }

    const reviewDealUrlEnabled = listingOverride?.reviewDealUrlEnabled || false;

    return {
        reviewDealUrlEnabled
    };
};

export const createDealerNode = (state: StateTree) => {
    const dealer = dealerSelectors.getDealerState(state);
    const toggles = featureToggleSelectors.getFeatureToggleState(state);
    return {
        dealerId: dealer.dealerId,
        dnaAccountId: dealer.dnaAccountId,
        useFnI2: dealer.useFnI2 || false,
        isCrmIntegrationEnabled: dealer.isCrmIntegrationEnabled,
        isDDCIntakeProcessorEnabledForDealer: toggles.isDDCIntakeProcessorEnabledForDealer || false,
        isFordBlueAdvantageEnabled: toggles.isFordBlueAdvantageEnabled || false,
        isTCPAEnabled: dealer.isTCPAEnabled || false,
        isTCPACheckboxDisabled: dealer.isTCPACheckboxDisabled || false,
        leadRoutingOverrideSettings: dealer.leadRoutingOverrideSettings,
        reviewDealUrlEnabled: dealer.reviewDealUrlEnabled || false,
        listingOverride: formatListingOverride(state),
        theme: state.dealer.theme,
        dealerTaxAddress: dealer.dealerTaxAddress
    };
};

export type Context = {
    listingCode?: string;
    shopperPageUrl: string;
    testDriveDeepLinkUrl?: string;
    vdpUrl?: string;
    sponsor?: string;
};

export type GatewayPayload = InitPayloadOffer & {
    dealer?: Dealer;
    tradeInVehicle: TradeInState;
    widgetSettings: { sponsor: string };
    financing: { term: number; annualMiles: any; apr: any };
    creditAppReferenceId: string;
    context?: Context;
    offer: GatewayOfferPayload;
};

export type GatewayOfferPayload = Offer & {
    type?: string;
    price?: number;
    initialPrice?: number;
    downPayment?: number;
    monthlyPaymentRounded?: number;
    messageToDealer?: string;
    offerId?: string;
    dealRefId?: string; // TODO: clean up dealRefId
    fsDealRefId?: string;
    amountFinanced?: number;
    dueAtSigning?: number;
    dealSummary?: DealSummaryItem[];
    cmdMessageToShopper?: string;
};

/**
 * Create the payload to hit the BFF Test Drive Endpoint
 * @param state - Current Application State
 */
export const createOfferPayload = (state: StateTree, offerUpdateType?: string): GatewayPayload => {
    // NOTE: This is most likely not needed, but we need to pass it to trade anyway so I'm keeping it hanging around until we've
    //   validated that it won't need to be implemented.
    return null as unknown as GatewayPayload;
};

const dashDebugAlert = (purpose: BuildPayloadPurpose, dealExchangeVersionId?: string): void => {
    if (!queryParamStore.isSmartOfferSavedDebuggingEnabled()) return;

    let purposeText: string;
    switch (purpose) {
        case BuildPayloadPurpose.AppInit: {
            purposeText = 'App Init';
            break;
        }
        case BuildPayloadPurpose.OfferSave: {
            purposeText = 'Offer Save';
            break;
        }
        case BuildPayloadPurpose.MenuInit: {
            purposeText = 'Menu Init';
            break;
        }
        case BuildPayloadPurpose.PushToDms: {
            purposeText = 'Push to DMS';
            break;
        }
        case BuildPayloadPurpose.TradeIn: {
            purposeText = 'Trade';
            break;
        }
        case BuildPayloadPurpose.DeveloperDebugging: {
            purposeText = 'Dev Debugging';
            break;
        }
        default: {
            purposeText = 'Unknown';
            break;
        }
    }
    console.log(
        `DASH DEBUG: "Smart Offer Save" - building offer payload for ${purposeText}, dealExchangeVersionId was "${dealExchangeVersionId}"`
    );
};
